// Styles
#root {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

// Main styles
@import "./config/default/app.scss";

// Hyper Icons
@import "./icons.scss";
